// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-me-js": () => import("./../src/pages/about-me.js" /* webpackChunkName: "component---src-pages-about-me-js" */),
  "component---src-pages-index-friend-links-friend-js": () => import("./../src/pages/index/friend-links/Friend.js" /* webpackChunkName: "component---src-pages-index-friend-links-friend-js" */),
  "component---src-pages-index-friend-links-friend-links-js": () => import("./../src/pages/index/friend-links/FriendLinks.js" /* webpackChunkName: "component---src-pages-index-friend-links-friend-links-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-photo-box-photo-box-js": () => import("./../src/pages/index/photo-box/PhotoBox.js" /* webpackChunkName: "component---src-pages-index-photo-box-photo-box-js" */),
  "component---src-pages-index-recently-item-js": () => import("./../src/pages/index/recently/Item.js" /* webpackChunkName: "component---src-pages-index-recently-item-js" */),
  "component---src-pages-index-recently-recently-js": () => import("./../src/pages/index/recently/Recently.js" /* webpackChunkName: "component---src-pages-index-recently-recently-js" */),
  "component---src-pages-index-shader-box-shader-box-js": () => import("./../src/pages/index/shader-box/ShaderBox.js" /* webpackChunkName: "component---src-pages-index-shader-box-shader-box-js" */),
  "component---src-pages-index-update-frequency-cell-js": () => import("./../src/pages/index/update-frequency/Cell.js" /* webpackChunkName: "component---src-pages-index-update-frequency-cell-js" */),
  "component---src-pages-index-update-frequency-column-js": () => import("./../src/pages/index/update-frequency/Column.js" /* webpackChunkName: "component---src-pages-index-update-frequency-column-js" */),
  "component---src-pages-index-update-frequency-update-frequency-js": () => import("./../src/pages/index/update-frequency/UpdateFrequency.js" /* webpackChunkName: "component---src-pages-index-update-frequency-update-frequency-js" */),
  "component---src-templates-article-js": () => import("./../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-article-list-js": () => import("./../src/templates/article-list.js" /* webpackChunkName: "component---src-templates-article-list-js" */),
  "component---src-templates-photo-js": () => import("./../src/templates/photo.js" /* webpackChunkName: "component---src-templates-photo-js" */),
  "component---src-templates-photo-list-js": () => import("./../src/templates/photo-list.js" /* webpackChunkName: "component---src-templates-photo-list-js" */),
  "component---src-templates-shader-js": () => import("./../src/templates/shader.js" /* webpackChunkName: "component---src-templates-shader-js" */),
  "component---src-templates-shader-list-js": () => import("./../src/templates/shader-list.js" /* webpackChunkName: "component---src-templates-shader-list-js" */),
  "component---src-templates-taxonomy-list-js": () => import("./../src/templates/taxonomy-list.js" /* webpackChunkName: "component---src-templates-taxonomy-list-js" */)
}

